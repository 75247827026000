import '@seed-design/stylesheet/global.css'
import './src/styles/global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const onClientEntry = () => {
  const viewport = document.querySelector('meta[name=viewport]')
  viewport.setAttribute(
    'content',
    'width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover',
  )
}
